.App {
  text-align: center;
  background-color: black;
  max-height: 100vh;
  height: 100vh;
  overflow: hidden;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.logo {
  font-family: "VT323", monospace;
  font-size: calc(100vw * 0.3);
  color: whitesmoke;
  transform: rotate(90deg);
  padding: 2.5%;
  max-height: 100vh;
}

.hero-img {
  height: 100vh;
  width: 100vw;
  position: absolute;
}

.hero-img img {
  height: 100%;
  width: 100%;
}

.hidden {
  display: none;
}

.slogan {
  font-size: calc(100vh * 0.0225);
  font-weight: "bold";
  color: rgba(245, 222, 179, 0.59);
  padding: 1.5%;
  text-transform: lowercase;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}